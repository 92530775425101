import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Button.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Box.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Text.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/OTP.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Input.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Password.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Text2.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Link.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Paragraph.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Radio.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/SVGButton.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Subtitle.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/TextError.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Title.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Accordion.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Item.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Alert.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Ul.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/AddTheme.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Theme.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Area.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/FileItem.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/GoogleAuth.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/ListItem.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Popup.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Spinner.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/NotFound.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Option.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Select.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Skeleton.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/Tooltip.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/kuui-react/dist/assets/VKAuth.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/icons/apple-touch-icon.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/icons/favicon-16x16.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/icons/favicon-32x32.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/icons/favicon.ico");
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/logo.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/AgreementPolicy/AgreementPolicy.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/BasicInfo/BasicInfo.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Browser.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/DragAndDropProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FAQ/FAQ.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FileLoader/FileLoader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FileLoaderProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/LoaderProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/NavBar/NavBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/NavBarBack/NavBarBack.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/NotificationsProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/QRCode/QRCode.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/SlidingBottom.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/SlidingLeft.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/downloadFileFromBuffer.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/writeTextIntoClipboard.ts")